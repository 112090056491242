<script lang="ts">
    import { twMerge } from 'tailwind-merge';
    import { getContext } from 'svelte';
    import type { Writable } from 'svelte/store';

    export let wrapperClass = '';
    export let defaultClass = 'flex items-center p-2 text-sm font-normal text-gray-700 dark:text-white rounded-lg w-full text-left';
    export let aClass = 'hover:bg-gray-200 dark:hover:bg-gray-700';
    export let href = '';
    export let label = '';
    export let spanClass = '';
    export let activeClass = 'font-bold text-primary-700 bg-gray-100 dark:bg-gray-700 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700';
    export let disabledClass = 'text-gray-400 cursor-not-allowed';

    export let nohover = false;
    export let active = false;
    export let disabled = false;

    let isFolded = getContext<Writable<boolean>>('isFolded');
</script>

<li class={wrapperClass}>
    <!-- svelte-ignore a11y-no-static-element-interactions -->
    <svelte:element
        this={href ? 'a' : 'button'}
        {...$$restProps}
        {href}
        on:blur
        on:click={e => {
            if (disabled || !href) e.preventDefault();
        }}
        on:click
        on:focus
        on:keydown
        on:keypress
        on:keyup
        on:mouseenter
        on:mouseleave
        on:mouseover
        class={twMerge(defaultClass, !disabled && aClass, !disabled && active && activeClass, disabled && disabledClass, nohover && 'hover:cursor-default hover:bg-transparent', $$props.class)}
    >
        {#if $isFolded}
            <span class="flex min-h-[1.3125rem] items-center">
                <slot name="icon" />
            </span>
        {:else}
            <div class="flex flex-1 items-center">
                <span class="min-w-2">
                    <slot name="icon" />
                </span>
                <span class="flex-1">
                    <span class={twMerge(spanClass, $$slots.icon && 'ml-2')}>{label}</span>
                    {#if $$slots.subtext}
                        <slot name="subtext" />
                    {/if}
                </span>
                {#if $$slots['right-icon']}
                    <slot name="right-icon" />
                {/if}
            </div>
        {/if}
    </svelte:element>
</li>

<!--
    @component
    ## Features
    [Go to Sidebar](https://flowbite-svelte.com/docs/components/sidebar)
    ## Props
    @prop aClass: string = 'flex items-center p-2 text-base font-normal text-gray-900 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700';
    @prop href: string = '';
    @prop label: string = '';
    @prop spanClass: string = 'ml-3';
    @prop activeClass: string = 'flex items-center p-2 text-base font-normal text-gray-900 bg-gray-200 dark:bg-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700';
    @prop active: boolean = false; 

    ## Example
    ```
    <script>
        import { page } from '$app/stores';
        import { Sidebar, SidebarGroup, SidebarItem, SidebarWrapper } from '$component/basic';
        let spanClass = 'flex-1 ml-3 whitespace-nowrap';
        $: activeUrl = $page.url.pathname
    </script>

    <Sidebar>
        <SidebarWrapper>
            <SidebarGroup>
                <SidebarItem label="Dashboard">
                    <svelte:fragment slot="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" /><path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" /></svg>
                    </svelte:fragment>
                </SidebarItem>
                <SidebarItem label="Kanban" {spanClass}>
                    <svelte:fragment slot="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z" /></svg>
                    </svelte:fragment>
                    <svelte:fragment slot="subtext">
                        <span
                          class="inline-flex justify-center items-center px-2 ml-3 text-sm font-medium text-gray-800 bg-gray-200 rounded-full dark:bg-gray-700 dark:text-gray-300"
                          >Pro</span
                        >
                    </svelte:fragment>
                </SidebarItem>
                <SidebarItem label="Inbox" {spanClass}>
                    <svelte:fragment slot="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M9 3.75H6.912a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H15M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859M12 3v8.25m0 0l-3-3m3 3l3-3" /></svg>
                    </svelte:fragment>
                    <svelte:fragment slot="subtext">
                        <span
                          class="inline-flex justify-center items-center p-3 ml-3 w-3 h-3 text-sm font-medium text-blue-600 bg-blue-200 rounded-full dark:bg-blue-900 dark:text-blue-200"
                          >3</span
                        >
                    </svelte:fragment>
                </SidebarItem>
                <SidebarItem label="Sidebar" href='/docs/components/sidebar' active={activeUrl === '/docs/components/sidebar'}>
                    <svelte:fragment slot="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" /></svg>
                    </svelte:fragment>
                </SidebarItem>
                <SidebarItem label="Sign In">
                    <svelte:fragment slot="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" /></svg>
                    </svelte:fragment>
                </SidebarItem>
                <SidebarItem label="Sign Up">
                    <svelte:fragment slot="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12a7.5 7.5 0 0015 0m-15 0a7.5 7.5 0 1115 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077l1.41-.513m14.095-5.13l1.41-.513M5.106 17.785l1.15-.964m11.49-9.642l1.149-.964M7.501 19.795l.75-1.3m7.5-12.99l.75-1.3m-6.063 16.658l.26-1.477m2.605-14.772l.26-1.477m0 17.726l-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205L12 12m6.894 5.785l-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864l-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495" /></svg>
                    </svelte:fragment>
                </SidebarItem>
            </SidebarGroup>
        </SidebarWrapper>
    </Sidebar>
    ```
  
-->
